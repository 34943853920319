import { EditageConfig } from 'config/onelogin.config';
import Cookies from 'js-cookie';
import ReactGA from 'react-ga4';
import ProfileService from 'services/ProfileService';
import getCleverTap from 'utils/clevertap';

export const AuthTypeEnum = {
  SAML: 'SAML',
  SSO: 'SSO',
};

export const AuthStatusEnum = {
  FULLY_AUTHENTICATED_SAML: 'FULLY_AUTHENTICATED_SAML',
  FULLY_AUTHENTICATED: 'FULLY_AUTHENTICATED',
  SSO_AUTHENTICATED: 'SSO_AUTHENTICATED',
  FULLY_UNAUTHENTICATED: 'FULLY_UNAUTHENTICATED',
  SSO_UNAUTHENTICATED: 'SSO_UNAUTHENTICATED',
};

const checkSSOAuth = () => {
  const ssoAuthCookie = EditageConfig.cookies.auidCookieKey;
  const ssoAuth = Cookies.get(ssoAuthCookie);
  return ssoAuth;
};

const checkMTGAuth = () => {
  const mtgAuthCookie = EditageConfig.cookies.idCookieKey;
  const mtgAuth = Cookies.get(mtgAuthCookie);
  return mtgAuth;
};

const checkAuid = (value) => {
  if (!value) {
    return null;
  }

  const [_, auid] = value.split('|');
  return auid;
};

export const setAuthenticationCookie = (token) => {
  Cookies.set(EditageConfig.cookies.idCookieKey, token, {
    expires: 0.25,
    path: '/',
    domain: '.mindthegraph.com',
  });
};

export const validateAuth = () => {
  const ssoAuth = checkSSOAuth();
  const mtgAuth = checkMTGAuth();
  const auidFromMTG = checkAuid(mtgAuth);
  const auidMatch = ssoAuth === auidFromMTG;
  const isAuthenticatedOneLogin = !!ssoAuth;
  const isAuthenticatedMTG = !!mtgAuth;

  if (!isAuthenticatedMTG) {
    if (!isAuthenticatedOneLogin) {
      return AuthStatusEnum.FULLY_UNAUTHENTICATED;
    }

    // User logged-in on OneLogin, update MTG user
    return AuthStatusEnum.SSO_AUTHENTICATED;
  }

  // Consider user SAML Authenticated
  if (!auidFromMTG) return AuthStatusEnum.FULLY_AUTHENTICATED_SAML;

  // User logged-out on OneLogin, logout MTG user
  if (!isAuthenticatedOneLogin) return AuthStatusEnum.SSO_UNAUTHENTICATED;

  // New user logged-in on OneLogin, update MTG user
  if (!auidMatch) return AuthStatusEnum.SSO_AUTHENTICATED;

  return AuthStatusEnum.FULLY_AUTHENTICATED;
};

export const getProfileData = async (id) => {
  const profileService = new ProfileService();
  const response = await profileService.fetchOne(id);
  return response.data;
};

export const validateProfile = (profile) => {
  const requiredFields = ['idRole', 'idStudyField', 'institution', 'gender'];
  const hasRequiredFields = requiredFields.reduce((acc, field) => {
    if (!!!profile[field]) {
      return false;
    }

    return acc;
  }, true);

  return hasRequiredFields;
};

export const deleteAuthCookie = () => {
  Cookies.remove(EditageConfig.cookies.idCookieKey, {
    path: '/',
    domain: '.mindthegraph.com',
  });
};

/**
 *
 * @param {number} ssoId - The ID of the SSO user
 * @param {string} partnerCode - The partner code (market) of the user
 * @param {boolean} isFirstLogin - Whether the user is logging in for the first time
 * @returns {void}
 */
export const sendTrackingEvents = (ssoId, partnerCode, isFirstLogin) => {
  if (isFirstLogin) {
    ReactGA.gtag('event', 'mtg_first_login', {
      sso_id: ssoId,
      login_method: 'email',
      language: 'en',
      market: partnerCode,
    });
  }
};

export const onUserLoginCT = async (profile, user) => {
  try {
    if (!user || !user.cactusId) {
      console.error('User or cactusId is missing');
      return;
    }

    const clevertap = await getCleverTap();

    if (!clevertap) {
      console.error('CleverTap is not available');
      return;
    }

    clevertap.onUserLogin.push({
      Site: {
        Identity: user.cactusId,
        Name: profile.name || '',
        Email: user.email || '',
        'Plan Type MTG': user.tp_user || '',
        Partner: user.partner || '',
      },
    });

    if (typeof user.isNewUser !== 'undefined' && user.isNewUser) {
      clevertap.event.push('sign-up', {
        Product: 'MTG',
        Email: user.email,
        'Country code': user.country_code,
        Description: 'Sign up',
      });
    }
  } catch (e) {
    console.error('Error in onUserLoginCT:', e);
  }
};
