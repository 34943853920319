import React, { createContext, useEffect, useState, useContext } from 'react';
import { datadogRum } from '@datadog/browser-rum';

const DatadogContext = createContext();

const DatadogProvider = ({ children, config }) => {
  const [isDatadogInitialized, setIsDatadogInitialized] = useState(false);

  const initializeDatadog = () => {
    if (!isDatadogInitialized) {
      try {
        datadogRum.init({
          applicationId: config.applicationId,
          clientToken: config.clientToken,
          site: 'datadoghq.com',
          service: 'mtg-frontend',
          env: config.env,
          version: '1.0.0',
          sampleRate: 100,
          trackInteractions: true,
          trackUserInteractions: true,
          silentMultipleInit: true,
          trackResources: true,
          trackLongTasks: true,
          defaultPrivacyLevel: 'mask-user-input',
        });
      } catch (error) {
        console.log('Failed to initialize Datadog RUM:', error);
      }

      setIsDatadogInitialized(true);
    }
  };

  /**
   * Set user information in Datadog RUM for better tracking and analytics
   *
   * @param {Object} user - User object containing user details
   * @param {string} user.id - User ID
   * @param {string} user.email - User email
   */
  const setDatadogUser = (user) => {
    if (!isDatadogInitialized) {
      initializeDatadog();
    }

    try {
      datadogRum.setUser({
        id: user.cactusId || '',
        email: user.email || '',
      });
    } catch (error) {
      console.error('Failed to set user information in Datadog RUM:', error);
    }
  };

  useEffect(() => {
    initializeDatadog();
  }, []);

  return (
    <DatadogContext.Provider value={{ initializeDatadog, setDatadogUser }}>
      {children}
    </DatadogContext.Provider>
  );
};

/**
 * Custom hook for using Datadog context
 * @returns {Object} Datadog context values
 */
const useDatadog = () => {
  const context = useContext(DatadogContext);
  if (context === undefined) {
    throw new Error('useDatadog must be used within a DatadogProvider');
  }
  return context;
};

export { DatadogProvider, DatadogContext, useDatadog };
