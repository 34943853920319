export const UserTypeEnum = {
  TRIAL: 'T',
  STUDENT: 'S',
  RESEARCHER: 'R',
  LAB_RESEARCHER: 'L',
  JOURNAL: 'J',
  ADMIN: 'A',
};

export const PlanTypeEnum = {
  INDIVIDUAL: 'INDIVIDUAL',
  TEAMS: 'TEAMS',
};

export const PlanEnum = {
  TRIAL: 'T',
  STUDENT: 'S',
  RESEARCHER: 'R',
  LAB_RESEARCHER: 'L',
  JOURNAL: 'J',
};

export const PlanIllustrationsEnum = {
  T: 4,
  S: 10,
  R: Infinity,
  J: Infinity,
  L: Infinity,
};

export const PlanEditableCreationsEnum = {
  T: 1,
  S: 2,
  R: Infinity,
  L: Infinity,
};

export const PlanLabelEnum = {
  T: 'Starter',
  S: 'Student',
  R: 'Researcher',
  L: 'Teams and Institutions',
  J: 'Journal',
  A: 'Admin',
};

export const PaymentMethodEnum = {
  CARD: 'card',
  PAYPAL: 'paypal',
  ALIPAY: 'alipay',
  SEPA_DEBIT: 'sepa_debit',
  WECHAT_PAY: 'wechat_pay',
};

/**
 * @typedef {'year'|'month'|'semester'|'quarter'} BillingIntervalID
 *
 * @typedef {'YEAR'|'MONTH'|'SEMESTER'|'QUARTER'} BillingIntervalKey
 */

/**
 * @type {Record<BillingIntervalKey, BillingIntervalID>}
 */
export const BillingIntervalEnum = {
  YEAR: 'year',
  MONTH: 'month',
  SEMESTER: 'semester',
  QUARTER: 'quarter',
};

export const GatewayEnum = {
  STRIPE: 'stripe',
  PAYPAL: 'paypal',
};

export const CouponStatusEnum = {
  AVAILABLE: 'available',
  REDEEMED: 'redeemed',
  INVALIDATED: 'invalidated',
};

export const CartAbandonmentReasonEnum = {
  PAYMENT_ISSUE: 'payment_issue',
  NOT_READY: 'not_ready',
  WRONG_PLAN: 'wrong_plan',
  TOO_EXPENSIVE: 'too_expensive',
  OTHER: 'other',
};

export const CartAbandonmentDetailEnum = {
  MISSING_PAYMENT_METHOD: 'missing_payment_method',
  CARD_DECLINED: 'card_declined',
  SLOW_PROCESSING: 'slow_processing',
  PAYPAL_VALIDATION: 'paypal_validation',
  ALIPAY_VALIDATION: 'alipay_validation',
  COMPARING_PRICES: 'comparing_prices',
  KNOWING_PLATFORM: 'knowing_platform',
  NOT_MEET_NEEDS: 'not_meet_needs',
  MISTAKE_ACCESS: 'mistake_access',
  OTHER: 'other',
};

export const CanvasToolbarEnum = {
  SHAPE: 'shape',
  DYNAMIC_SHAPE: 'dynamic_shape',
  DYNAMIC_LINE: 'DynamicLine',
  RIGHT_ANGLE_LINE: 'right_angle_line',
  ILLUSTRATION: 'illustration',
  ICON: 'icon',
  ACTIVE_SELECTION: 'activeSelection',
  GROUP: 'group',
  MOLECULE: 'molecule',
  ARROW: 'arrow',
  TEXT: 'textbox',
  UPLOAD: 'upload',
  CHART: 'chart',
  GENERATION: 'generation',
};

export const TextAlignTypeEnum = {
  LEFT: 'left',
  RIGHT: 'right',
  CENTER: 'center',
  JUSTIFY: 'justify',
};

export const TextListTypeEnum = {
  NORMAL: 'normal',
  BULLET: 'bullet',
  NUMERED: 'numered',
};

export const KeyCodeTypeEnum = {
  ENTER: 13,
  BACKSPACE: 8,
  DELETE: 46,
  CTRL: 17,
  CMD: 91,
  C: 67,
  V: 86,
  Z: 90,
  Y: 89,
  LEFT: 37,
  UP: 38,
  RIGHT: 39,
  DOWN: 40,
};

export const SharePermissionEnum = {
  EDIT: 'editor',
  COMMENT: 'commentor',
};

export const WorkspaceModalsEnum = {
  ILLUSTRATION_REQUEST_FORM: 'illustration_request_form',
  ILLUSTRATION_REQUEST_UPSELL: 'illustration_request_upsell',
  ILLUSTRATION_REQUEST_SUCCEDED: 'illustration_request_succeded',
  ILLUSTRATION_REQUEST_SUBSCRIPTION_CONFIRMED: 'illustration_request_subscription_confirmed',
  ILLUSTRATION_REQUEST_CANCELLED: 'illustration_request_cancelled',
  ILLUSTRATION_REQUEST_START_SUBSCRIPTION: 'illustration_request_start_subscription',
  ILLUSTRATION_REQUEST_CHECKOUT_FORM: 'illustration_request_checkout_form',
  ILLUSTRATION_LIMIT: 'illustration_limit',
  TRIAL_REQUEST_SUBSCRIPTION_CONFIRMED: 'trial_request_subscription_confirmed',
  TRIAL_REQUEST_CANCELLED: 'trial_request_cancelled',
  LIMITATION_MODAL: 'limitation_modal',
  LIMITATION_REQUEST_ILLUSTRATION_MODAL: 'limitation_request_illustration_modal',
  CHECKOUT_FORM: 'checkout_form',
  UPLOAD_LIMIT: 'upload_limit',
  TRIAL_OFFER: 'trial_offer',
  UPGRADE_OFFER: 'upgrade_offer',
  BLACK_NOVEMBER: 'black_november',
  PLANS_COMPARISON: 'plans_comparison',
  SLIDE_LIMIT: 'slide_limit',
  DOWNLOAD: 'download',
  DOWNLOAD_UPSELL: 'download-upsell',
  CREATIONS_UPSELL: 'creations_upsell',
  IMAGE_EDITION: 'image_edition',
  MARCELATOR: 'marcelator',
  REMOVE_FAVORITE: 'remove_favorite',
  REMOVE_UPLOAD: 'remove_upload',
  SAVE_AS: 'save_as',
  SHARE: 'share',
  SLIDE_COPY: 'slide_copy',
  CONTACT_US: 'contact_us',
  SLIDE_DELETE: 'slide_delete',
  NEW_CREATION: 'new_creation',
  TEMPLATE_APPLY: 'template_apply',
  CHART: 'chart',
  BANNER_TRIAL_OFFER: 'banner_trial_offer',
  ANNIVERSARY_MTG: 'anniversary_mtg',
  TEXT_TO_IMAGE_OFFER: 'text_to_image_offer',
  TEXT_TO_IMAGE_LIMIT: 'text_to_image_limit',
  TEXT_TO_IMAGE_CUSTOM: 'text_to_image_custom',
  TRIAL_MINUTES_LIMITATION_MODAL: 'trial_minutes_limitation_modal',
};

export const ShapesEnum = {
  ABSTRACT: 'abstract',
  SLIDE: 'slide',
  INFOGRAPHIC: 'infographic',
  POSTER: 'poster',
  CUSTOM: 'custom',
};

export const LandingPageContextEnum = {
  GRAPHICAL_ABSTRACT_MAKER: 'graphical_abstract',
  POSTER_MAKER: 'poster_maker',
  SCIENCE_FIGURES: 'science_figures',
  CREATE_GRAPHICAL_ABSTRACT: 'create_graphical_abstract',
  KOREA_TECH: 'korea_tech',
  HOME_PAGE: 'home_page',
};

export const SocketEventEnum = {
  USER_UPDATED: 'user.updated',
};

export const MinAndMaxCreationSizeLimit = {
  MIN_WIDTH: 100,
  MAX_WIDTH: 2500,
  MIN_HEIGHT: 100,
  MAX_HEIGHT: 2500,
  MIN: 100,
  MAX: 2500,
};

export const PackTypeEnum = {
  STUDENT: 'student',
  RESEARCHER: 'researcher',
};
