import { createContext, useState, useContext, useEffect } from 'react';
import CreationService from 'services/CreationService';
import { useQuery } from '@tanstack/react-query';
import { useAuthUser } from 'features/auth/AuthUserContext';

const CreationsContext = createContext({
  creations: [],
  setCreations: () => {},
  loading: false,
  setLoading: () => {},
});

export function CreationsProvider({ children }) {
  const [creations, setCreations] = useState([]);
  const [loading, setLoading] = useState(false);
  const value = { creations, setCreations, loading, setLoading };

  return <CreationsContext.Provider value={value}>{children}</CreationsContext.Provider>;
}

export function useCreations() {
  const { user } = useAuthUser();
  const { creations, setCreations, loading, setLoading } = useContext(CreationsContext);

  const { refetch } = useQuery({
    queryKey: ['creations'],
    queryFn: async () => {
      if (user?.id) {
        setLoading(true);
        const response = await CreationService.findAll({ status: 'available' });
        setCreations(response.data);
        setLoading(false);
        return response.data;
      }
    },
    options: {
      enabled: false,
    },
  });

  useEffect(() => {
    if (user) {
      refetch();
    }
  }, [user, refetch]);

  return [creations, refetch, loading];
}
