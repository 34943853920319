import React, { useEffect, useCallback } from 'react';

let timeoutId = null;

/**
 * High Order Component that wraps a component and adds user inactivity tracking.
 * If the user is inactive for a certain period of time, the user is logged out and redirected to the login page.
 *
 * @param {React.ComponentType} Component - The component to wrap.
 * @returns {React.ComponentType} A new component that includes the inactivity tracking.
 */
const withActivityTimeout = (Component) => {
  const WithActivityTimeout = (props) => {
    const startTimer = useCallback(() => {
      if (!localStorage.getItem('mtg_user')) {
        return;
      }

      if (timeoutId) clearTimeout(timeoutId);

      timeoutId = setTimeout(
        () => {
          localStorage.removeItem('mtg_user');
          window.location.href = '/auth/login-register';
        },

        2 * 60 * 60 * 1000,
      );
    }, []);

    useEffect(() => {
      startTimer();
      const events = ['load', 'mousemove', 'mousedown', 'click', 'scroll', 'keypress'];

      events.forEach((event) => {
        window.addEventListener(event, startTimer);
      });

      return () => {
        if (timeoutId) clearTimeout(timeoutId);
        events.forEach((event) => {
          window.removeEventListener(event, startTimer);
        });
      };
    }, [
      startTimer,
      typeof window !== 'undefined' ? window.localStorage.getItem('mtg_user') : null,
    ]);

    return <Component {...props} />;
  };

  WithActivityTimeout.displayName = `WithActivityTimeout(${getDisplayName(Component)})`;

  return WithActivityTimeout;
};

/**
 * Get the display name of a component.
 *
 * @param {React.ComponentType} Component - The component to get the display name of.
 * @returns {string} The display name of the component.
 */
function getDisplayName(Component) {
  return Component.displayName || Component.name || 'Component';
}

export default withActivityTimeout;
