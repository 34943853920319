/**
 * Returns the current CleverTap instance. If `window.clevertap` is not defined,
 * returns a fallback object with empty push methods.
 * @returns { { event: { push: (eventName: string, eventProperties: object) => void }, onUserLogin: { push: (userDetails: object) => void }, profile: { push: (profileData: object) => void } } }
 */
export default function getCleverTap() {
  if (typeof window !== 'undefined' && window.clevertap) {
    return window.clevertap;
  } else {
    return {
      event: { push: () => {} },
      onUserLogin: { push: () => {} },
      profile: { push: () => {} },
    };
  }
}
