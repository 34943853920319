import { createGlobalStyle } from 'styled-components';
import { normalize } from 'styled-normalize';

import { FONT_FAMILY } from './variables/typography';
import { COLOR_GRAY_90, COLOR_PURPLE } from './variables/colors';
import fonts from './fonts';

export default createGlobalStyle`
  ${normalize}
  ${fonts}

  * {
    box-sizing: border-box;
  }

  body {
    color: ${COLOR_GRAY_90};
    font-family: ${FONT_FAMILY};
  }

  html{
    scroll-behavior: smooth;
  }
  
  li,
  label,
  input {
    font-family: ${FONT_FAMILY} !important;
  }

  button, a {
    outline: 0;
    cursor: pointer;
  }

  a {
    text-decoration: underline;
    color: ${COLOR_PURPLE};
  }

  @media (min-width: 1280px) {
    .MuiContainer-root.MuiContainer-maxWidthLg {
      max-width: 1280px !important;
    }
  }
`;
