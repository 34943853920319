import ApiService from './ApiService';

export default class ProfileService {
  fetchOne(id) {
    return ApiService.get(`/profiles/${id}`);
  }

  update(id, body) {
    return ApiService.patch(`/profiles/${id}`, body);
  }

  updatePassword(id, body) {
    return ApiService.patch(`/profiles/${id}/password`, body);
  }

  getPlan() {
    return ApiService.get(`/profiles/plan`);
  }

  getGatewayLastInvoice() {
    return ApiService.get(`/profiles/plan/gateway-last-invoice`);
  }

  getLastInvoice() {
    return ApiService.get(`/profiles/plan/last-invoice`);
  }

  getUser(id) {
    return ApiService.get(`/profiles/${id}/user`);
  }

  getBillingHistory(id) {
    return ApiService.get(`/profiles/${id}/invoice`);
  }

  getCreations(id) {
    return ApiService.get(`/profiles/${id}/creations`);
  }

  createFeedback(data) {
    return ApiService.post(`/profiles/plan/feedback`, data);
  }

  cancelPlan() {
    return ApiService.put(`/profiles/plan/cancel`);
  }

  deleteAccount() {
    return ApiService.delete(`profiles`);
  }
}
