import Axios from 'axios';
import { EditageConfig } from 'config/onelogin.config';
import Cookies from 'js-cookie';
import { getEnv } from 'utils';
import https from 'https';

const axios = Axios.create({
  baseURL: getEnv('MTG_URL_API'),
  httpsAgent: new https.Agent({
    // Only disable certificate validation in development
    rejectUnauthorized: process.env.NODE_ENV !== 'development' ? true : false,
  }),
});

axios.interceptors.request.use(async (config) => {
  if (typeof window !== 'undefined') {
    const cookieKey = EditageConfig.cookies.idCookieKey;
    const userCookie = Cookies.get(cookieKey);

    if (userCookie) {
      config.headers.Authorization = `Bearer ${userCookie}`;
    }
  }

  return config;
});

export default axios;
