import { createContext, useState, useContext } from 'react';
import Cookie from 'js-cookie';

const INITIAL_STATE = Cookie.get('breadcrumbs') ? JSON.parse(Cookie.get('breadcrumbs')) : [];

const BreadcrumbsStateContext = createContext();
const BreadcrumbsDispatchContext = createContext();

export function BreadcrumbsProvider({ children }) {
  const [state, setState] = useState(INITIAL_STATE);

  const dispatch = (payload, persist = false) => {
    if (persist) {
      Cookie.set('breadcrumbs', JSON.stringify(payload));
    }

    setState(payload);
  };

  return (
    <BreadcrumbsStateContext.Provider value={state}>
      <BreadcrumbsDispatchContext.Provider value={dispatch}>
        {children}
      </BreadcrumbsDispatchContext.Provider>
    </BreadcrumbsStateContext.Provider>
  );
}

export function useBreadcrumbsContext() {
  const state = useContext(BreadcrumbsStateContext);
  const dispatch = useContext(BreadcrumbsDispatchContext);

  if (state === undefined || dispatch === undefined) {
    throw new Error('useBreadcrumbsContext must be used within a BreadcrumbsProvider');
  }

  return [state, dispatch];
}
