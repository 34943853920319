import { useRouter } from 'next/router';
import { createContext, useCallback, useContext, useState, useEffect } from 'react';
import { AuthService, OneLoginService } from 'services/AuthService';
import { useDatadog } from 'contexts/DatadogContext';
import {
  AuthTypeEnum,
  AuthStatusEnum,
  validateAuth,
  validateProfile,
  getProfileData,
  deleteAuthCookie,
  onUserLoginCT,
} from './utils';
import ProfileService from 'services/ProfileService';
import { EditageConfig } from 'config/onelogin.config';
import Cookies from 'js-cookie';
import { parseJwt } from 'utils';
import JournalService from 'services/JournalService';

const AuthUserContext = createContext();

const LOGOUT_PAGE_PATH = '/auth/logout';
const TOKEN_VALIDATION_INTERVAL = 10 * 60 * 1000; // 10 minutes, based on the API cache

export function AuthUserProvider({ children }) {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [lastTokenValidationTimestamp, setLastTokenValidationTimestamp] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState(null);
  const [profile, setProfile] = useState(null);
  const [userJournalSettings, setUserJournalSettings] = useState(null);
  const [shouldCompleteProfile, setShouldCompleteProfile] = useState(false);
  const [authType, setAuthType] = useState(AuthTypeEnum.SSO);
  const router = useRouter();
  const { setDatadogUser } = useDatadog();

  /**
   * Update the user data in the context, and handles the side-effects of it
   *
   * @param {Object} userData
   */
  const updateUser = async (userData, authType = AuthTypeEnum.SSO) => {
    let data = userData;

    if (!userData) {
      const response = await new ProfileService().getUser(user.id);
      data = response.data;
    }

    setUser(data);
    const profile = await getProfileData(data.id);
    setProfile(profile);
    const userJournalSettings = await JournalService.getUserSettings();
    setUserJournalSettings(userJournalSettings.data);
    const isProfileComplete = validateProfile(profile);
    setShouldCompleteProfile(!isProfileComplete);
    setIsAuthenticated(true);
    setAuthType(authType);
    onUserLoginCT(profile, data);
    setDatadogUser(data);
  };

  /**
   * Complete the user profile, and updates the context data
   *
   * @param {Object} profileData
   * @returns {void}
   */
  const completeProfile = async (profileData) => {
    const response = await AuthService.completeProfile(profileData);
    setProfile(response.data);
    setShouldCompleteProfile(false);
  };

  /**
   * Remove the user data from the context, and deletes the auth cookie if it exists
   *
   * @returns {void}
   */
  const removeUser = () => {
    deleteAuthCookie();
    setUser(null);
    setProfile(null);
    setUserJournalSettings(null);
    setIsAuthenticated(false);
  };

  /**
   * Validate the session, and updates the context if the user is authenticated
   * If the user is not authenticated, it will redirect to the login page
   * If the user is authenticated, it will update the user data in the context
   *
   * @param {Object} options
   * @param {boolean} options.triggerLogin - If the user should be redirected to the login page if not authenticated
   * @param {boolean} options.backgroundValidation - If the validation should be done in the background, not triggering the loading state
   * @param {string} options.redirectTo - The page to redirect to after validation, optional
   * @returns {void}
   */
  const validateSession = async ({
    triggerLogin = false,
    backgroundValidation = false,
    redirectTo,
  } = {}) => {
    if (!backgroundValidation) {
      setIsLoading(true);
    }

    try {
      const authStatus = validateAuth();
      const shouldValidateToken =
        !lastTokenValidationTimestamp ||
        Date.now() - lastTokenValidationTimestamp >= TOKEN_VALIDATION_INTERVAL;

      if (
        authStatus === AuthStatusEnum.FULLY_AUTHENTICATED &&
        (!isAuthenticated || shouldValidateToken)
      ) {
        const response = await OneLoginService.validate();

        if (!response.data?.active) {
          removeUser();

          if (triggerLogin) {
            login({ redirectPage: redirectTo || router.pathname });
          }

          return false;
        }

        // Sync states with actual authentication
        if (!isAuthenticated) {
          const { user: userData } = response.data;
          setDatadogUser(userData);
          await updateUser(userData);
        }

        setLastTokenValidationTimestamp(Date.now());
        return true;
      }

      if (
        authStatus === AuthStatusEnum.FULLY_AUTHENTICATED_SAML &&
        (!isAuthenticated || shouldValidateToken)
      ) {
        const response = await AuthService.validateSamlUser();

        if (!isAuthenticated && response.data) {
          await updateUser(response.data.user, AuthTypeEnum.SAML);
        }

        setLastTokenValidationTimestamp(Date.now());
        return true;
      }

      // Should not execute on the logout page, as the `__id` will be deleted
      if (authStatus === AuthStatusEnum.SSO_AUTHENTICATED && router.pathname !== LOGOUT_PAGE_PATH) {
        const response = await OneLoginService.authenticate();
        const { user: userData } = response.data;
        userData.isNewUser = response.data.isNewUser;
        await updateUser(userData);
        return true;
      }

      if (
        authStatus === AuthStatusEnum.SSO_UNAUTHENTICATED ||
        authStatus === AuthStatusEnum.FULLY_UNAUTHENTICATED
      ) {
        removeUser();

        if (triggerLogin) {
          login({ redirectPage: redirectTo || router.pathname });
        }

        return false;
      }

      return (
        authStatus === AuthStatusEnum.FULLY_AUTHENTICATED ||
        authStatus === AuthStatusEnum.FULLY_AUTHENTICATED_SAML
      );
    } catch (error) {
      console.error('Error validating session:', error);
      if (triggerLogin) {
        login({ redirectPage: redirectTo || router.pathname });
      }
      return false;
    } finally {
      setIsLoading(false);
    }
  };

  /**
   * Redirect to the complete profile page
   *
   * @returns {void}
   */
  const redirectToCompleteProfile = () => {
    const redirectUrl = `${window.location.origin}${router.asPath}`;
    router.push(`/auth/complete-profile?redirect=${encodeURIComponent(redirectUrl)}`);
  };

  /**
   * Redirect to the login page
   *
   * @param {Object} options
   * @param {string} options.redirectPage
   * @param {boolean} options.isFullUrl - If the redirect page is a full URL or only the path
   * @returns {void}
   */
  const redirectToLogin = async ({ redirectPage, isFullUrl } = {}) => {
    const redirectTo = redirectPage || '/my-creations';
    const redirectUrl = isFullUrl ? redirectPage : `${window.location.origin}${redirectTo}`;
    // Have to do this because the useGeoData hook is not available from the AuthUserContext
    const geoData = localStorage.getItem('geoData');
    const country = geoData ? JSON.parse(geoData).country_code : 'US';
    const domain =
      country === 'CN' ? EditageConfig.sso.chineseDomain : EditageConfig.sso.mainDomain;
    window.location.href = `${domain}/login?continue=${encodeURIComponent(redirectUrl)}`;
  };

  /**x`
   * Redirect to the logout page
   *
   * @param {Object} options
   * @param {string} options.redirectPage
   * @param {boolean} options.isFullUrl - If the redirect page is a full URL or only the path
   * @param {string} options.issLogoutUrl - The URL to logout
   * @returns {void}
   */
  const redirectToLogout = async ({ redirectPage, isFullUrl, issLogoutUrl } = {}) => {
    const redirectTo = redirectPage || '/my-creations';
    const redirectUrl = isFullUrl ? redirectPage : `${window.location.origin}${redirectTo}`;
    window.location.href = `https://${issLogoutUrl}/logout?continue=${encodeURIComponent(
      redirectUrl,
    )}`;
  };

  /**
   * Redirect to the register page
   *
   * @param {string} redirectPage - The page to redirect to after registration
   * @returns {void}
   */
  const register = useCallback(async (redirectPage) => {
    const redirectTo = redirectPage || router.pathname;
    const redirectUrl = `${window.location.origin}${redirectTo}`;
    window.location.href = `${EditageConfig.sso.mainDomain}/register?continue=${encodeURIComponent(
      redirectUrl,
    )}`;
  }, []);

  /**
   * Login the user, and redirects to the login page if the user is not authenticated
   *
   * @param {Object} options
   * @param {string} options.redirectPage - The page to redirect to after login
   * @param {boolean} options.isFullUrl - If the redirect page is a full URL or only the path
   * @returns {void}
   */
  const login = useCallback(async ({ redirectPage, isFullUrl }) => {
    try {
      if (!isAuthenticated) {
        redirectToLogin({ redirectPage, isFullUrl });
        return;
      }

      await validateSession();
    } catch (error) {
      redirectToLogin({ redirectPage, isFullUrl });
    }

    if (redirectPage) {
      router.push(redirectPage);
    } else {
      router.push(router.pathname);
    }
  }, []);

  /**
   * Logout the user, and redirects to the logout page
   *
   * @returns {void}
   */
  const logout = useCallback(async () => {
    if (authType === AuthTypeEnum.SAML) {
      removeUser();
      router.push('/');
      return;
    }

    const userEditageToken = Cookies.get(EditageConfig.cookies.idCookieKey);
    const parsedUserEditageToken = userEditageToken ? parseJwt(userEditageToken) : {};
    const issLogoutUrl = parsedUserEditageToken.iss;

    removeUser();
    redirectToLogout({ issLogoutUrl });
  }, [authType]);

  useEffect(() => {
    validateSession();
  }, []);

  useEffect(() => {
    const onRouteChange = () => {
      validateSession();
    };

    router.events.on('routeChangeComplete', onRouteChange);
    return () => router.events.off('routeChangeComplete', onRouteChange);
  }, [validateSession]);

  return (
    <AuthUserContext.Provider
      value={{
        isAuthenticated,
        isLoading,
        user,
        profile,
        userJournalSettings,
        shouldCompleteProfile,
        login,
        logout,
        register,
        redirectToCompleteProfile,
        completeProfile,
        updateUser,
        authType,
        validateSession,
      }}
    >
      {children}
    </AuthUserContext.Provider>
  );
}

/**
 * @typedef {Object} AuthUserContextType
 * @property {boolean} isAuthenticated - Boolean indicating if the user is authenticated
 * @property {boolean} isLoading - Boolean indicating if the context is still loading
 * @property {Object} user - User data
 * @property {Object} profile - Profile data
 * @property {Object} userJournalSettings - User journal settings
 * @property {boolean} shouldCompleteProfile - Boolean indicating if the user should complete the profile
 * @property {Function} login - Function to login the user
 * @property {Function} logout - Function to logout the user
 * @property {Function} register - Function to register the user
 * @property {Function} redirectToCompleteProfile - Function to redirect to the complete profile page
 * @property {Function} completeProfile - Function to complete the user profile
 * @property {Function} updateUser - Function to update the user data in the context
 * @property {Function} validateSession - Function to validate the session
 * @property {string} authType - The type of authentication (SAML or SSO)
 */

/**
 * Hook to consume data from the AuthUserContext, including authentication status, user data, profile data, and methods to login, logout, register, and redirect to complete profile.
 *
 * @returns {AuthUserContextType} AuthUserContext data and methods
 * @throws {Error} If the hook is not used within an AuthUserProvider
 */
export const useAuthUser = () => {
  const context = useContext(AuthUserContext);

  if (!context) {
    throw new Error('useAuthUser must be used within an AuthUserProvider');
  }

  return {
    isAuthenticated: context.isAuthenticated,
    isLoading: context.isLoading,
    user: context.user,
    userJournalSettings: context.userJournalSettings,
    profile: context.profile,
    shouldCompleteProfile: context.shouldCompleteProfile,
    login: context.login,
    logout: context.logout,
    register: context.register,
    redirectToCompleteProfile: context.redirectToCompleteProfile,
    completeProfile: context.completeProfile,
    updateUser: context.updateUser,
    authType: context.authType,
    validateSession: context.validateSession,
  };
};
