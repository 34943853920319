import ApiService from './ApiService';
import * as queryString from 'query-string';

export default class CreationService {
  static findAll(params) {
    return ApiService.get(`/user-creations?${queryString.stringify(params)}`);
  }

  static findOne(id) {
    return ApiService.get(`/user-creations/${id}`);
  }

  static findOneBySlug(slug) {
    return ApiService.get(`/user-creations/slug/${slug}`);
  }

  static findAllShared(params) {
    return ApiService.get(`/user-creations/shared?${queryString.stringify(params)}`);
  }

  static findAllDeleted(params) {
    return ApiService.get(`/user-creations/deleted?${queryString.stringify(params)}`);
  }

  static create(data) {
    return ApiService.post('/user-creations', data);
  }

  static update(id, data) {
    return ApiService.put(`/user-creations/${id}`, data);
  }

  static delete(id) {
    return ApiService.delete(`/user-creations/${id}`);
  }

  static restore(id) {
    return ApiService.put(`/user-creations/${id}/restore`);
  }

  static deleteSharing(id) {
    return ApiService.delete(`/user-creations/${id}/sharing`);
  }

  static findAllFolders() {
    return ApiService.get('/user-creations/folders');
  }

  static createFolder(data) {
    return ApiService.post('/user-creations/folders', data);
  }

  static updateFolder(id, data) {
    return ApiService.put(`/user-creations/folders/${id}`, data);
  }

  static deleteFolder(id) {
    return ApiService.delete(`/user-creations/folders/${id}`);
  }

  static createSlide(id, data) {
    return ApiService.post(`/user-creations/${id}/slide`, data);
  }

  static duplicateSlide(id, slideId) {
    return ApiService.post(`/user-creations/${id}/slide/${slideId}/duplicate`);
  }

  static deleteSlide(id, slideId) {
    return ApiService.delete(`/user-creations/${id}/slide/${slideId}`);
  }

  static updateSlideContent(id, slideId, data) {
    return ApiService.put(`/user-creations/${id}/slide/${slideId}/content`, data);
  }

  static getSlideContent(id, slideId) {
    return ApiService.get(`/user-creations/${id}/slide/${slideId}/content`);
  }

  static copySlide(id, slideId, data) {
    return ApiService.post(`/user-creations/${id}/slide/${slideId}/copy`, data);
  }

  static deleteSlide(id, slideId) {
    return ApiService.delete(`/user-creations/${id}/slide/${slideId}`);
  }

  static download(id, data) {
    return ApiService.post(`/user-creations/${id}/download`, data, {
      responseType: 'blob',
    });
  }

  static downloadSlide(id, slideId, data) {
    return ApiService.post(`/user-creations/${id}/slide/${slideId}/download`, data, {
      responseType: 'blob',
    });
  }

  static downloadSlideAsSvg(id, slideId, data) {
    return ApiService.post(`/user-creations/${id}/slide/${slideId}/download-svg`, data);
  }

  static updateSlideByCreation(id, data) {
    return ApiService.put(`/user-creations/${id}/slide`, data);
  }

  static updateSlidesSequences(id, data) {
    return ApiService.put(`/user-creations/${id}/slide/sequences`, data);
  }

  static getAllShares(id) {
    return ApiService.get(`/user-creations/${id}/share`);
  }

  static share(id, permission, email, user) {
    const data = {
      permission,
      email,
      user,
    };

    return ApiService.post(`/user-creations/${id}/share`, data);
  }

  static updateShare(id, shareId, permission) {
    return ApiService.put(`/user-creations/${id}/share/${shareId}`, { permission });
  }

  static deleteShare(id, shareId) {
    return ApiService.delete(`/user-creations/${id}/share/${shareId}`);
  }

  static getAllComments(id, slideId) {
    return ApiService.get(`/user-creations/${id}/slide/${slideId}/comment`);
  }

  static createComment(id, slideId, elementId, comment) {
    return ApiService.post(`/user-creations/${id}/slide/${slideId}/comment`, {
      elementId,
      comment,
    });
  }

  static updateComment(id, slideId, commentId, comment, checked) {
    return ApiService.put(`/user-creations/${id}/slide/${slideId}/comment/${commentId}`, {
      comment,
      checked,
    });
  }

  static deleteComment(id, slideId, commentId) {
    return ApiService.delete(`/user-creations/${id}/slide/${slideId}/comment/${commentId}`);
  }

  static getAllBackups(creationId, slideId) {
    return ApiService.get(`/user-creations/${creationId}/slide/${slideId}/backups`);
  }

  static getVersion(key, versionId) {
    return ApiService.post(`/user-creations/get-slide-version`, { key, versionId });
  }

  static convertCreationToNewWorkspace(creationId) {
    return ApiService.post(`/user-creations/${creationId}/convertCreationToNewWorkspace`);
  }
}
