import ApiService from './ApiService';

export default class JournalService {
  static getUserSettings() {
    return ApiService.get('journals/settings');
  }

  static getMembershipStatus() {
    return ApiService.get('journals/memberships');
  }
}
