import React, { useEffect } from 'react';
import { useRouter } from 'next/router';
import { useAuthUser } from 'features/auth/AuthUserContext';

export default function Page({ Component, pageProps }) {
  const router = useRouter();
  const { user, isLoading } = useAuthUser();

  useEffect(() => {
    function handleIntercomStart() {
      const help = router.asPath.includes('#help');

      setTimeout(
        () => {
          if (window.Intercom) {
            window.Intercom('boot', {
              app_id: 'gvophsej',
              api_base: 'https://api-iam.intercom.io',
              email: user.email,
              user_id: user.id,
            });
            help && window.Intercom('show');
          }
        },
        help ? 100 : 5500,
      );
    }

    if (!isLoading && user?.id) {
      handleIntercomStart();
    }
  }, [user, isLoading]);

  return <Component {...pageProps} />;
}
