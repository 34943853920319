import { createContext, useState, useContext, useEffect } from 'react';

/**
 * Enum for type of gallery.
 * @readonly
 * @enum {string}
 */
export const GalleryType = {
  Illustration: 'ILLUSTRATION',
  Template: 'TEMPLATE',
};

const INITIAL_STATE = {
  type: GalleryType.Illustration,
  tags: [],
  activeTag: undefined,
};

const GalleryStateContext = createContext();
const GalleryDispatchContext = createContext();

// Provide the global gallery state down to tree
export function GalleryProvider({ children }) {
  const [state, setState] = useState(INITIAL_STATE);

  useEffect(() => {
    const persistedState = localStorage.getItem('gallery');

    if (persistedState) {
      setState(JSON.parse(persistedState));
    }
  }, []);

  const dispatch = (payload) => {
    const nextState = { ...state, ...payload };

    localStorage.setItem('gallery', JSON.stringify(nextState));
    setState(nextState);
  };

  return (
    <GalleryStateContext.Provider value={state}>
      <GalleryDispatchContext.Provider value={dispatch}>{children}</GalleryDispatchContext.Provider>
    </GalleryStateContext.Provider>
  );
}

/**
 * Hook for consume the gallery state
 * @returns {Array} An array that contains the state in position 0 and
 * the dispatch function in the position 1
 */
export function useGalleryContext() {
  const state = useContext(GalleryStateContext);
  const dispatch = useContext(GalleryDispatchContext);

  if (state === undefined || dispatch === undefined) {
    throw new Error('useTermsModalContext must be used within a TermsModalProvider');
  }

  return [state, dispatch];
}
