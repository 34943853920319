export const COLOR_WHITE = '#ffffff';
export const COLOR_ORANGE_LIGHT = '#ff9226';

export const COLOR_PURPLE = '#7833ff';
export const COLOR_PURPLE_HOVER = '#5F29CC';
export const COLOR_PURPLE_DARK = '#6119ff';

export const COLOR_PINK_BRAND = '#dc1866';
export const COLOR_PINK = '#d6246e';

export const COLOR_RED = '#f01c10';

// Grayscale
export const COLOR_GRAY_00 = '#fafafa';
export const COLOR_GRAY_10 = '#f5f5f5';
export const COLOR_GRAY_20 = '#eeeeee';
export const COLOR_GRAY_30 = '#e0e0e0';
export const COLOR_GRAY_40 = '#bdbdbd';
export const COLOR_GRAY_50 = '#9e9E9e';
export const COLOR_GRAY_60 = '#757575';
export const COLOR_GRAY_70 = '#616161';
export const COLOR_GRAY_80 = '#424242';
export const COLOR_GRAY_90 = '#212121';

// Third-party
export const COLOR_FACEBOOK = '#1977f3';
export const COLOR_FACEBOOK_DARK = '#024CAE';
export const COLOR_LINKEDIN = '#007BB9';
export const COLOR_TWITTER = '#2EAAE2';

// Brand linear gradient
export const COLOR_BRAND_GRADIENT = `linear-gradient(
  190.99deg,
  ${COLOR_PINK} 8.64%,
  ${COLOR_ORANGE_LIGHT} 107.82%
)`;
