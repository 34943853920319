import Cookies from 'js-cookie';
import axios from 'axios';
import ApiService from './ApiService';
import { getEnv } from 'utils';
import { EditageConfig } from 'config/onelogin.config';
import { sendTrackingEvents, setAuthenticationCookie } from 'features/auth/utils';

const AuthService = {
  loginWithFacebook(data) {
    return ApiService.post('/auth/login-with-facebook', data);
  },

  verifyTokenValidity(token) {
    return ApiService.get('/auth/verify-token-validity', {
      headers: { Authorization: `Bearer ${token}` },
    });
  },

  loginWithGoogle(data) {
    return ApiService.post('/auth/login-with-google', data);
  },

  login(data) {
    return ApiService.post('/auth/login', data);
  },

  tokenLogin(data) {
    return ApiService.post('/auth/token-login', data);
  },

  sendEmailUnlockindUser(data) {
    const params = new URLSearchParams();
    params.append('email', data.email);
    params.append('key', data.key_unlockind);

    return axios.post(`${getEnv('MTG_URL_LEGACY')}/ajax/unlockind_email`, params, {
      withCredentials: true,
    });
  },

  logoutLegacy() {
    axios.post(`${getEnv('MTG_URL_LEGACY')}/login/logout`, null, {
      withCredentials: true,
    });
  },

  register(data, recaptchaToken) {
    return ApiService.post('/auth/register', data, { headers: { Recaptcha: recaptchaToken } });
  },

  roles() {
    return ApiService.get('/roles');
  },

  customInformation() {
    return ApiService.get('/customInformation');
  },

  completeProfile(data) {
    return ApiService.patch(`/auth/complete-profile`, data);
  },

  data() {
    return ApiService.get('/payments/data');
  },

  usacoEmail(data) {
    return ApiService.post('/auth/usaco-email', data);
  },

  async getUserFromToken(token) {
    try {
      const response = await axios.get(`${getEnv('MTG_URL_API')}/auth/profile/?token=${token}`, {
        withCredentials: true, // Ensures the cookies are sent with the request
      });

      if (response.status !== 200) {
        throw new Error('Failed to fetch user profile');
      }

      return response.data;
    } catch (error) {
      console.error(error);
      throw new Error('Error retrieving user profile');
    }
  },

  async logout() {
    return axios.post('/auth/logout', {}, { withCredentials: true });
  },

  async getIdentityProviderConfigs() {
    return ApiService.get('/auth/saml/idp-config');
  },

  async getIdentityProviderConfig(entityId) {
    return ApiService.get(`/auth/saml/idp-config/${entityId}`);
  },

  async createIdentityProviderConfig(data) {
    return ApiService.post('/auth/saml/idp-config', data);
  },

  async updateIdentityProviderConfig(entityId, data) {
    return ApiService.put(`/auth/saml/idp-config/${entityId}`, data);
  },

  async getIdpAuthenticationUrl(entityId) {
    return ApiService.get(`/auth/saml/initiate/${entityId}`);
  },

  async authenticateWithSAML(token) {
    const response = await ApiService.get(`/auth/saml/complete-authentication?token=${token}`);
    const { access_token } = response.data;
    setAuthenticationCookie(access_token);

    return response.data.user;
  },

  async validateSamlUser() {
    return ApiService.get('/auth/saml/validate');
  },
};

const oneloginApiService = axios.create({
  baseURL: EditageConfig.sso.federatedDomain,
});

oneloginApiService.interceptors.request.use(async (config) => {
  config.withCredentials = true;
  return config;
});

const OneLoginService = {
  async authenticate() {
    const response = await oneloginApiService.get('/oauth/token');
    const auid = Cookies.get(EditageConfig.cookies.auidCookieKey);

    const composedToken = `${response.data.access_token}|${auid}`;

    setAuthenticationCookie(composedToken);

    const url = new URL(window.location.href);
    const authMode = url.searchParams.get('mode');

    try {
      const shouldUpdateUserProductAssoc = !authMode;
      if (shouldUpdateUserProductAssoc) {
        await axios.get(`${EditageConfig.sso.mainDomain}/api/user-product-association`, {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${response.data.access_token}`,
          },
          data: {
            product: 'mtg',
            onboarding_status: 'pending',
          },
        });
      }
    } catch (err) {
      console.error(`Error trying to post user product association: ${err}`);
    }

    const callbackData = {
      token: composedToken,
    };

    const callbackResponse = await ApiService.post('/auth/onelogin/callback', callbackData);
    const authData = callbackResponse.data;
    const isFirstLogin = authData.isNewUser;

    sendTrackingEvents(authData.user.cactusId, authData.user.partner, isFirstLogin);

    return callbackResponse;
  },

  validate() {
    return ApiService.get('/auth/onelogin/validate');
  },

  async shortSignup(data) {
    return ApiService.post('/auth/short-signup', data);
  },
};

export { AuthService, OneLoginService };
