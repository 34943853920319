const PARTNER_URL_BASE = `https://${
  process.env.EDITAGE_PARTNER_URLS_PREFIX !== 'prod'
    ? `${process.env.EDITAGE_PARTNER_URLS_PREFIX}.app`
    : 'app'
}.editage`;

/**
 * @description EditageConfig is a configuration object that contains all the configuration values for the Editage application.
 * @type {Object}
 * @property {Object} cookies - Configuration object for cookies
 * @property {string} auidCookieKey - The key for the auid cookie `__auid-test1`
 * @property {string} idCookieKey - The key for the id cookie `__id-test1`
 * @property {Object} sso - Configuration object for SSO
 * @property {string} federatedDomain - The federated domain for SSO `https://test1.sso.mindthegraph.com`
 * @property {string} mainDomain - The main domain for SSO `https://test1.sso.editage.com`
 * @property {string} chineseDomain - The chinese domain for SSO `https://test1.sso.editage.cn`
 * @property {Object} partnerUrl - Configuration object for partner URLs based on the partner ID `1: 'test1.app.editage.jp'`
 */
export const EditageConfig = {
  cookies: {
    auidCookieKey: process.env.AUID_COOKIE_KEY,
    idCookieKey: process.env.ID_COOKIE_KEY,
  },
  sso: {
    federatedDomain: process.env.SSO_FEDERATED_DOMAIN,
    mainDomain: process.env.SSO_MAIN_DOMAIN,
    chineseDomain: process.env.SSO_CHINESE_DOMAIN,
  },
  partnerUrl: {
    1: `${PARTNER_URL_BASE}.jp`,
    2: `${PARTNER_URL_BASE}.com.tw`,
    5: `${PARTNER_URL_BASE}.co.kr`,
    10: `${PARTNER_URL_BASE}.cn`,
    11: `${PARTNER_URL_BASE}.com`,
    13: `${PARTNER_URL_BASE}.de`,
    15: `${PARTNER_URL_BASE}.in`,
    16: `${PARTNER_URL_BASE}.com.br`,
  },
  geoIp: {
    endpoint: 'https://geoip.cactusglobal.io/json/',
  },
};
