import { createContext, useState, useContext } from 'react';

const initialState = {
  id: null,
  userName: null,
  userEmail: null,
  type: 'graphical_abstract',
  quantity: 1,
  express: false,
  total: 200,
  specs: [],
};

const CustomServiceContext = createContext();

export function CustomServiceProvider({ children }) {
  const [state, setState] = useState({ ...initialState });
  const value = [state, (newState) => setState(newState || { ...initialState })];

  return <CustomServiceContext.Provider value={value}>{children}</CustomServiceContext.Provider>;
}

export function useCustomServiceContext() {
  const [state, dispatch] = useContext(CustomServiceContext);

  return [state, dispatch];
}
