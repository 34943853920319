import CreationService from 'services/CreationService';
import ProfileService from 'services/ProfileService';
import { UserTypeEnum } from '../enums';
import stopwords from './stopwords.json';
import * as queryString from 'query-string';
import { PlanEnum } from 'enums';

export const flowTrialActive = false;

export function getEnv(key) {
  let value = '';

  switch (key) {
    case 'APP_ENV':
      value = process.env.APP_ENV;
      break;
    case 'MTG_URL_APP':
      value = process.env.MTG_URL_APP;
      break;
    case 'MTG_URL_API':
      value = process.env.MTG_URL_API;
      break;
    case 'MTG_URL_SEARCH':
      value = process.env.MTG_URL_SEARCH;
      break;
    case 'MTG_URL_LEGACY':
      value = process.env.MTG_URL_LEGACY;
      break;
    case 'MTG_URL_PUBLISHERS':
      value = process.env.MTG_URL_PUBLISHERS;
      break;
    case 'MTG_BUCKET_URL_TEMPLATES':
      value = process.env.TEMPLATES_BUCKET_URL;
      break;
    case 'MTG_BUCKET_URL_USERS':
      value = process.env.USERS_BUCKET_URL;
      break;
    case 'STRIPE_API_KEY':
      value = process.env.STRIPE_API_KEY;
      break;
    case 'STRIPE_JAPAN_API_KEY':
      value = process.env.STRIPE_JAPAN_API_KEY;
      break;
    case 'GTM_TRACKING_ID':
      value = process.env.GTM_TRACKING_ID;
      break;
    case 'GA_TRACKING_ID':
      value = process.env.GA_TRACKING_ID;
      break;
    case 'GA_TRACKING_ID_ALT':
      value = process.env.GA_TRACKING_ID_ALT;
      break;
    case 'GO_TRACKING_ID':
      value = process.env.GO_TRACKING_ID;
      break;
    case 'GA_MEASUREMENT_ID':
      value = process.env.GA_MEASUREMENT_ID;
      break;
    case 'FB_PIXEL_ID':
      value = process.env.FB_PIXEL_ID;
      break;
    case 'R_LIFE_BILLING':
      value = process.env.R_LIFE_BILLING;
      break;
    case 'R_LIFE_BILLING_CHINA':
      value = process.env.R_LIFE_BILLING_CHINA;
      break;
    case 'R_LIFE_SUBSCRIPTION_API_URL':
      value = process.env.R_LIFE_SUBSCRIPTION_API_URL;
      break;
  }

  if (!value) {
    console.error(`Missing value for environment variable "${key}".`);
  }

  return value;
}

export function extractBase64FromUrl(base64Url) {
  const urlParts = base64Url.split(';');

  return urlParts[1].replace('base64,', '');
}

export async function fileToBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => {
      resolve({
        content: extractBase64FromUrl(reader.result),
        url: reader.result,
      });
    });
    reader.addEventListener('error', (error) => reject(error));
    reader.readAsDataURL(file);
  });
}

export function blobToBase64(blob) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => {
      const urlParts = reader.result.split(';');
      const encodedContent = urlParts[1].replace('base64,', '');

      resolve({
        content: encodedContent,
        url: reader.result,
      });
    });
    reader.addEventListener('error', (error) => reject(error));
    reader.readAsDataURL(blob);
  });
}

export async function fileUrlToBase64(url) {
  const data = await fetch(url);
  const blob = await data.blob();

  return await blobToBase64(blob);
}

export function downloadBlob(blob, fileName) {
  const linkElement = document.createElement('a');

  linkElement.href = URL.createObjectURL(new Blob([blob]));
  linkElement.setAttribute('download', fileName);

  document.body.appendChild(linkElement);

  linkElement.click();

  linkElement.remove();
}

export function getErrorMessage({ response: { data } }) {
  const error = data?.message instanceof Array ? data?.message?.[0] : data?.message;

  return error || 'Unexpected error.';
}

export function calculateCentimeterPixels(centimeters) {
  const centimeterPixels = 28.346456693;

  return parseInt(parseInt(centimeters) * centimeterPixels);
}

export function scrollToElement(elementOrElementId) {
  const element =
    typeof elementOrElementId === 'string'
      ? document.getElementById(elementOrElementId)
      : elementOrElementId;

  if (element) {
    element.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }
}

export function blackNovemberPromotionData() {
  const currentDate = new Date().getTime();
  const preActiveStartDate = new Date('2023-10-28T00:00:00').getTime();
  const preActiveEndDate = new Date('2023-11-01T00:00:00').getTime();
  const activeStartDate = new Date('2023-11-01T00:00:00').getTime();
  const activeEndDate = new Date('2023-12-04T00:00:00').getTime();

  return {
    isPreActive: currentDate > preActiveStartDate && currentDate < preActiveEndDate,
    preActiveStartDate,
    preActiveEndDate,
    isActive: currentDate > activeStartDate && currentDate < activeEndDate,
    activeStartDate,
    activeEndDate,
  };
}

export function isUserEligibleForBlackNovember(user, profile) {
  return [UserTypeEnum.TRIAL, UserTypeEnum.STUDENT].includes(user?.tp_user) && !profile?.journal;
}

/**
 * Checks if the user is from journal.
 * @param {Object} profile - The profile object of the user.
 * @returns {boolean} - Returns true if the user is from journal, otherwise false.
 */
export function isUserFromJournal(profile) {
  if (!profile || !profile.journal) {
    return false;
  }

  return ['journal_urology', 'plastic_reconstructive_surgery'].includes(profile.journal);
}

export function isAnniversaryActive() {
  const currentDate = new Date().getTime();

  return (
    currentDate > new Date('2024-01-22T00:00:00').getTime() &&
    currentDate < new Date('2024-03-01T00:00:00').getTime()
  );
}

export function openIntercomChat() {
  const browserWindow = window;
  const intercom = browserWindow.Intercom;

  if (typeof intercom === 'function') {
    intercom('show');
  }
}

export function updateCFBucketUrls(content) {
  const data = JSON.stringify(content);
  let cleanedData = data.replaceAll('cf-', '');
  cleanedData = cleanedData.replaceAll('s3-us-west-2.amazonaws.com/', '');
  return JSON.parse(cleanedData);
}

export function isUserEligibleForAnniversaryCampaign(user, profile) {
  return [UserTypeEnum.TRIAL, UserTypeEnum.STUDENT].includes(user?.tp_user) && !profile?.journal;
}

export function userIsAvailableForNewTrialFlow(user) {
  const createdDate = new Date(user.dt_begin).getTime();
  const datePermission = new Date('2024-04-02T01:00:00').getTime();
  const isStarter = user.tp_user === 'T';

  return isStarter && createdDate > datePermission;
}

export async function checkUserForTrialOptions(authenticated) {
  if (!authenticated) {
    return false;
  }
  const profileService = new ProfileService();
  const isUserFromChinaOrJapan = ['CN', 'JP'].includes(authenticated?.country_code);
  const createdDate = new Date(authenticated.dt_begin).getTime();
  const datePermission = new Date('2024-04-02T01:00:00').getTime();
  const accountDateHasPermission = createdDate > datePermission;

  async function getLastBillingInterval() {
    if (authenticated) {
      const { data: lastInvoice } = await profileService.getLastInvoice();

      return !!lastInvoice;
    }
  }

  const userLastInvoice = await getLastBillingInterval();
  const userCanViewTrial =
    flowTrialActive &&
    !isUserFromChinaOrJapan &&
    !userLastInvoice &&
    authenticated.tp_user === UserTypeEnum.TRIAL &&
    accountDateHasPermission;

  return userCanViewTrial;
}

export async function timeSinceFirstCreation() {
  const { data: allCreations } = await CreationService.findAll({ status: 'available' });

  const currentTime = Date.now();
  const dateOfFirstCreation = new Date(allCreations[0].created).getTime();
  const minutesSinceLastCreation = (currentTime - dateOfFirstCreation) / 60000;

  return minutesSinceLastCreation;
}

export function removeStopwords(text) {
  // to import from stopwords.json we need to use the following code:
  const sw = stopwords.words;
  const words = text.split(' ');
  return words.filter((word) => !sw.includes(word)).join(' ');
}

export function formatDate(date) {
  const dateObject = new Date(date);
  const day = `${('0' + dateObject.getDate()).slice(-2)}`;
  const month = `${('0' + (dateObject.getMonth() + 1)).slice(-2)}`;
  const year = dateObject.getFullYear();

  return `${day}/${month}/${year}`;
}

export function formatDateTime(date) {
  const dateObject = new Date(date);
  const day = `${('0' + dateObject.getDate()).slice(-2)}`;
  const month = `${('0' + (dateObject.getMonth() + 1)).slice(-2)}`;
  const year = dateObject.getFullYear();
  const hours = `${('0' + dateObject.getHours()).slice(-2)}`;
  const minutes = `${('0' + dateObject.getMinutes()).slice(-2)}`;

  return `${day}/${month}/${year} ${hours}:${minutes}`;
}

/**
 * @description Function to parse a JWT token
 * @param {string} token - JWT token
 * @returns {Object} - Decoded JWT token
 * @example
 * const token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6Ik...'
 * const decodedToken = parseJwt(token)
 */
export function parseJwt(token) {
  try {
    return JSON.parse(atob(token.split('.')[1]));
  } catch (e) {
    return null;
  }
}

/**
 * @description Function to get the continue URL for the MTG from onecheckout
 */
export function getMtgContinueUrl(continueUrl = '/my-account/overview/') {
  return getEnv('MTG_URL_APP') + continueUrl;
}

/*
 * @description get redirect url for china vs row
 */
export const getOneCheckoutHostUrl = (userCountry = null) => {
  return userCountry && userCountry.toLowerCase() === 'cn'
    ? getEnv('R_LIFE_BILLING_CHINA')
    : getEnv('R_LIFE_BILLING');
};

export const makeCheckoutUrl = (planType, userCountry, checkoutParams) => {
  let checkoutHostUrl = getOneCheckoutHostUrl(userCountry);
  let params = queryString.stringify(checkoutParams);

  if (
    [PlanEnum.LAB_RESEARCHER, PlanEnum.STUDENT].includes(planType) ||
    userCountry.toLowerCase() === 'cn'
  ) {
    return `${checkoutHostUrl}/checkout?${params}&continue=${getMtgContinueUrl()}`;
  }

  return `${checkoutHostUrl}/checkout-subscribe?${params}&continue=${getMtgContinueUrl()}`;
};
