import { createContext, useState, useContext, useEffect } from 'react';
import TeamService from 'services/TeamService';
import { useAuthUser } from 'features/auth/AuthUserContext';

const initialState = {
  id: null,
  name: '',
  invites: [],
};

const TeamContext = createContext([{ ...initialState }, () => {}]);

export function TeamProvider({ children }) {
  const [state, setState] = useState({ ...initialState });
  const value = [state, (newState) => setState(newState)];

  return <TeamContext.Provider value={value}>{children}</TeamContext.Provider>;
}

export function useTeamContext() {
  const [state, dispatch] = useContext(TeamContext);
  const { user, isAuthenticated } = useAuthUser();

  async function fetchData() {
    try {
      const service = new TeamService();
      const { data } = await service.myTeam();

      dispatch(data);
    } catch (e) {
      console.error(e);
    }
  }

  useEffect(() => {
    if (user && isAuthenticated) {
      fetchData();
    }
  }, [user, isAuthenticated]);

  return [
    state,
    (newState) => {
      if (newState) {
        dispatch({ ...state, ...newState });
      } else {
        fetchData();
      }
    },
  ];
}
