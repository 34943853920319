import { css } from 'styled-components';

export default css`
  @font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src:
      local('Lato Light'),
      local('Lato-Light'),
      url(/fonts/lato-light.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113,
      U+2C60-2C7F, U+A720-A7FF;
  }
  /* latin */
  @font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src:
      local('Lato Light'),
      local('Lato-Light'),
      url(/fonts/lato-light-latin.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC,
      U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }
  /* latin-ext */
  @font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src:
      local('Lato Regular'),
      local('Lato-Regular'),
      url(/fonts/lato-regular-latinext.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113,
      U+2C60-2C7F, U+A720-A7FF;
  }
  /* latin */
  @font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src:
      local('Lato Regular'),
      local('Lato-Regular'),
      url(/fonts/lato-regular-latin.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC,
      U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }
  /* latin-ext */
  @font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src:
      local('Lato Bold'),
      local('Lato-Bold'),
      url(/fonts/lato-bold-latinext.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113,
      U+2C60-2C7F, U+A720-A7FF;
  }
  /* latin */
  @font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src:
      local('Lato Bold'),
      local('Lato-Bold'),
      url(/fonts/lato-bold-latin.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC,
      U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }
  /* latin-ext */
  @font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 900;
    font-display: swap;
    src:
      local('Lato Black'),
      local('Lato-Black'),
      url(/fonts/lato-black-latinext.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113,
      U+2C60-2C7F, U+A720-A7FF;
  }
  /* latin */
  @font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 900;
    font-display: swap;
    src:
      local('Lato Black'),
      local('Lato-Black'),
      url(/fonts/lato-black-latin.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC,
      U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }

  @font-face {
    font-family: 'Material Icons';
    font-style: normal;
    font-weight: 400;
    src: url(/fonts/material-icons.woff2) format('woff2');
    font-display: swap;
  }

  .material-icons {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    font-feature-settings: 'liga';
    -webkit-font-smoothing: antialiased;
  }

  /* workspace fonts */
  @font-face {
    font-family: 'museo_slab500';
    src: url('/fonts/museo_slab_500_2-webfont.eot');
    src:
      url('/fonts/museo_slab_500_2-webfont.eot?#iefix') format('embedded-opentype'),
      url('/fonts/museo_slab_500_2-webfont.woff') format('woff'),
      url('/fonts/museo_slab_500_2-webfont.ttf') format('truetype'),
      url('/fonts/museo_slab_500_2-webfont.svg#museo_slab500') format('svg');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'francois_oneregular';
    src: url('/fonts/francoisone-webfont.eot');
    src:
      url('/fonts/francoisone-webfont.eot?#iefix') format('embedded-opentype'),
      url('/fonts/francoisone-webfont.woff') format('woff'),
      url('/fonts/francoisone-webfont.ttf') format('truetype'),
      url('/fonts/francoisone-webfont.svg#francois_oneregular') format('svg');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'roboto_slabbold';
    src: url('/fonts/robotoslab-bold-webfont.eot');
    src:
      url('/fonts/robotoslab-bold-webfont.eot?#iefix') format('embedded-opentype'),
      url('/fonts/robotoslab-bold-webfont.woff') format('woff'),
      url('/fonts/robotoslab-bold-webfont.ttf') format('truetype'),
      url('/fonts/robotoslab-bold-webfont.svg#roboto_slabbold') format('svg');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'roboto_slabregular';
    src: url('/fonts/robotoslab-regular-webfont.eot');
    src:
      url('/fonts/robotoslab-regular-webfont.eot?#iefix') format('embedded-opentype'),
      url('/fonts/robotoslab-regular-webfont.woff') format('woff'),
      url('/fonts/robotoslab-regular-webfont.ttf') format('truetype'),
      url('/fonts/robotoslab-regular-webfont.svg#roboto_slabregular') format('svg');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'caviar_dreamsbold_italic';
    src: url('/fonts/caviardreams_bolditalic-webfont.eot');
    src:
      url('/fonts/caviardreams_bolditalic-webfont.eot?#iefix') format('embedded-opentype'),
      url('/fonts/caviardreams_bolditalic-webfont.woff') format('woff'),
      url('/fonts/caviardreams_bolditalic-webfont.ttf') format('truetype'),
      url('/fonts/caviardreams_bolditalic-webfont.svg#caviar_dreamsbold_italic') format('svg');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'caviar_dreamsregular';
    src: url('/fonts/caviardreams-webfont.eot');
    src:
      url('/fonts/caviardreams-webfont.eot?#iefix') format('embedded-opentype'),
      url('/fonts/caviardreams-webfont.woff') format('woff'),
      url('/fonts/caviardreams-webfont.ttf') format('truetype'),
      url('/fonts/caviardreams-webfont.svg#caviar_dreamsregular') format('svg');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'caviar_dreamsitalic';
    src: url('/fonts/caviardreams_italic-webfont.eot');
    src:
      url('/fonts/caviardreams_italic-webfont.eot?#iefix') format('embedded-opentype'),
      url('/fonts/caviardreams_italic-webfont.woff') format('woff'),
      url('/fonts/caviardreams_italic-webfont.ttf') format('truetype'),
      url('/fonts/caviardreams_italic-webfont.svg#caviar_dreamsitalic') format('svg');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'caviar_dreamsbold';
    src: url('/fonts/caviar_dreams_bold-webfont.eot');
    src:
      url('/fonts/caviar_dreams_bold-webfont.eot?#iefix') format('embedded-opentype'),
      url('/fonts/caviar_dreams_bold-webfont.woff') format('woff'),
      url('/fonts/caviar_dreams_bold-webfont.ttf') format('truetype'),
      url('/fonts/caviar_dreams_bold-webfont.svg#caviar_dreamsbold') format('svg');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'misobold';
    src: url('/fonts/miso-bold-webfont.eot');
    src:
      url('/fonts/miso-bold-webfont.eot?#iefix') format('embedded-opentype'),
      url('/fonts/miso-bold-webfont.woff') format('woff'),
      url('/fonts/miso-bold-webfont.ttf') format('truetype'),
      url('/fonts/miso-bold-webfont.svg#misobold') format('svg');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'misoregular';
    src: url('/fonts/miso-regular-webfont.eot');
    src:
      url('/fonts/miso-regular-webfont.eot?#iefix') format('embedded-opentype'),
      url('/fonts/miso-regular-webfont.woff') format('woff'),
      url('/fonts/miso-regular-webfont.ttf') format('truetype'),
      url('/fonts/miso-regular-webfont.svg#misoregular') format('svg');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'nunitobold';
    src: url('/fonts/nunito-bold-webfont.eot');
    src:
      url('/fonts/nunito-bold-webfont.eot?#iefix') format('embedded-opentype'),
      url('/fonts/nunito-bold-webfont.woff') format('woff'),
      url('/fonts/nunito-bold-webfont.ttf') format('truetype'),
      url('/fonts/nunito-bold-webfont.svg#nunitobold') format('svg');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'nunitoregular';
    src: url('/fonts/nunito-regular-webfont.eot');
    src:
      url('/fonts/nunito-regular-webfont.eot?#iefix') format('embedded-opentype'),
      url('/fonts/nunito-regular-webfont.woff') format('woff'),
      url('/fonts/nunito-regular-webfont.ttf') format('truetype'),
      url('/fonts/nunito-regular-webfont.svg#nunitoregular') format('svg');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'signikabold';
    src: url('/fonts/signika-bold-webfont.eot');
    src:
      url('/fonts/signika-bold-webfont.eot?#iefix') format('embedded-opentype'),
      url('/fonts/signika-bold-webfont.woff') format('woff'),
      url('/fonts/signika-bold-webfont.ttf') format('truetype'),
      url('/fonts/signika-bold-webfont.svg#signikabold') format('svg');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'signikaregular';
    src: url('/fonts/signika-regular-webfont.eot');
    src:
      url('/fonts/signika-regular-webfont.eot?#iefix') format('embedded-opentype'),
      url('/fonts/signika-regular-webfont.woff') format('woff'),
      url('/fonts/signika-regular-webfont.ttf') format('truetype'),
      url('/fonts/signika-regular-webfont.svg#signikaregular') format('svg');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'breeserifregular';
    src: url('/fonts/breeserif-regular-webfont.eot');
    src:
      url('/fonts/breeserif-regular-webfont.eot?#iefix') format('embedded-opentype'),
      url('/fonts/breeserif-regular-webfont.woff') format('woff'),
      url('/fonts/breeserif-regular-webfont.ttf') format('truetype'),
      url('/fonts/breeserif-regular-webfont.svg#breeserifregular') format('svg');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'comfortaaregular';
    src: url('/fonts/comfortaa-regular-webfont.eot');
    src:
      url('/fonts/comfortaa-regular-webfont.eot?#iefix') format('embedded-opentype'),
      url('/fonts/comfortaa-regular-webfont.woff') format('woff'),
      url('/fonts/comfortaa-regular-webfont.ttf') format('truetype'),
      url('/fonts/comfortaa-regular-webfont.svg#comfortaaregular') format('svg');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'comfortaabold';
    src: url('/fonts/comfortaa-bold-webfont.eot');
    src:
      url('/fonts/comfortaa-bold-webfont.eot?#iefix') format('embedded-opentype'),
      url('/fonts/comfortaa-bold-webfont.woff') format('woff'),
      url('/fonts/comfortaa-bold-webfont.ttf') format('truetype'),
      url('/fonts/comfortaa-bold-webfont.svg#comfortaabold') format('svg');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'grandhotelregular';
    src: url('/fonts/grandhotel-regular-webfont.eot');
    src:
      url('/fonts/grandhotel-regular-webfont.eot?#iefix') format('embedded-opentype'),
      url('/fonts/grandhotel-regular-webfont.woff') format('woff'),
      url('/fonts/grandhotel-regular-webfont.ttf') format('truetype'),
      url('/fonts/grandhotel-regular-webfont.svg#grandhotelregular') format('svg');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'merriweatherregular';
    src: url('/fonts/merriweather-regular-webfont.eot');
    src:
      url('/fonts/merriweather-regular-webfont.eot?#iefix') format('embedded-opentype'),
      url('/fonts/merriweather-regular-webfont.woff') format('woff'),
      url('/fonts/merriweather-regular-webfont.ttf') format('truetype'),
      url('/fonts/merriweather-regular-webfont.svg#merriweatherregular') format('svg');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'merriweatherbold';
    src: url('/fonts/merriweather-bold-webfont.eot');
    src:
      url('/fonts/merriweather-bold-webfont.eot?#iefix') format('embedded-opentype'),
      url('/fonts/merriweather-bold-webfont.woff') format('woff'),
      url('/fonts/merriweather-bold-webfont.ttf') format('truetype'),
      url('/fonts/merriweather-bold-webfont.svg#merriweatherbold') format('svg');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'merriweatheritalic';
    src: url('/fonts/merriweather-italic-webfont.eot');
    src:
      url('/fonts/merriweather-italic-webfont.eot?#iefix') format('embedded-opentype'),
      url('/fonts/merriweather-italic-webfont.woff') format('woff'),
      url('/fonts/merriweather-italic-webfont.ttf') format('truetype'),
      url('/fonts/merriweather-italic-webfont.svg#merriweatheritalic') format('svg');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'merriweatherbold_italic';
    src: url('/fonts/merriweather-bolditalic-webfont.eot');
    src:
      url('/fonts/merriweather-bolditalic-webfont.eot?#iefix') format('embedded-opentype'),
      url('/fonts/merriweather-bolditalic-webfont.woff') format('woff'),
      url('/fonts/merriweather-bolditalic-webfont.ttf') format('truetype'),
      url('/fonts/merriweather-bolditalic-webfont.svg#merriweatherbold_italic') format('svg');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'oleoscriptregular';
    src: url('/fonts/oleoscript-regular-webfont.eot');
    src:
      url('/fonts/oleoscript-regular-webfont.eot?#iefix') format('embedded-opentype'),
      url('/fonts/oleoscript-regular-webfont.woff') format('woff'),
      url('/fonts/oleoscript-regular-webfont.ttf') format('truetype'),
      url('/fonts/oleoscript-regular-webfont.svg#oleoscriptregular') format('svg');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'oleoscriptbold';
    src: url('/fonts/oleoscript-bold-webfont.eot');
    src:
      url('/fonts/oleoscript-bold-webfont.eot?#iefix') format('embedded-opentype'),
      url('/fonts/oleoscript-bold-webfont.woff') format('woff'),
      url('/fonts/oleoscript-bold-webfont.ttf') format('truetype'),
      url('/fonts/oleoscript-bold-webfont.svg#oleoscriptbold') format('svg');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'questrialregular';
    src: url('/fonts/questrial-regular-webfont.eot');
    src:
      url('/fonts/questrial-regular-webfont.eot?#iefix') format('embedded-opentype'),
      url('/fonts/questrial-regular-webfont.woff') format('woff'),
      url('/fonts/questrial-regular-webfont.ttf') format('truetype'),
      url('/fonts/questrial-regular-webfont.svg#questrialregular') format('svg');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'open_sansbold';
    src: url('/fonts/opensans-bold-webfont.eot');
    src:
      url('/fonts/opensans-bold-webfont.eot?#iefix') format('embedded-opentype'),
      url('/fonts/opensans-bold-webfont.woff') format('woff'),
      url('/fonts/opensans-bold-webfont.ttf') format('truetype'),
      url('/fonts/opensans-bold-webfont.svg#open_sansbold') format('svg');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'open_sansbold_italic';
    src: url('/fonts/opensans-bolditalic-webfont.eot');
    src:
      url('/fonts/opensans-bolditalic-webfont.eot?#iefix') format('embedded-opentype'),
      url('/fonts/opensans-bolditalic-webfont.woff') format('woff'),
      url('/fonts/opensans-bolditalic-webfont.ttf') format('truetype'),
      url('/fonts/opensans-bolditalic-webfont.svg#open_sansbold_italic') format('svg');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'open_sansitalic';
    src: url('/fonts/opensans-italic-webfont.eot');
    src:
      url('/fonts/opensans-italic-webfont.eot?#iefix') format('embedded-opentype'),
      url('/fonts/opensans-italic-webfont.woff') format('woff'),
      url('/fonts/opensans-italic-webfont.ttf') format('truetype'),
      url('/fonts/opensans-italic-webfont.svg#open_sansitalic') format('svg');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'open_sansregular';
    src: url('/fonts/opensans-regular-webfont.eot');
    src:
      url('/fonts/opensans-regular-webfont.eot?#iefix') format('embedded-opentype'),
      url('/fonts/opensans-regular-webfont.woff') format('woff'),
      url('/fonts/opensans-regular-webfont.ttf') format('truetype'),
      url('/fonts/opensans-regular-webfont.svg#open_sansregular') format('svg');
    font-weight: normal;
    font-style: normal;
  }
`;
