import React, { useState, createContext, useContext } from 'react';

const TemplateContext = createContext();

export function TemplateProvider({ children }) {
  const [template, setTemplate] = useState();

  return (
    <TemplateContext.Provider
      value={{
        template,
        setTemplate,
      }}
    >
      {children}
    </TemplateContext.Provider>
  );
}

export function useTemplate() {
  return useContext(TemplateContext);
}
