import Head from 'next/head';
import ReactGA from 'react-ga4';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import GlobalStyle from 'styles/GlobalStyle';
import Script from 'next/script';
import TagManager from 'react-gtm-module';
import { LinkedInInsightTag } from 'nextjs-linkedin-insight-tag';
import { BreadcrumbsProvider } from 'contexts/breadcrumbsContext';
// import { TermsModalProvider } from 'contexts/termsModalContext';
import { CreationsProvider } from 'contexts/useCreations';
import { GalleryProvider } from 'contexts/galleryContext';
// import { ForgotProvider } from 'contexts/forgotContext';
// import { PosterProvider } from 'contexts/usePoster';
import { GraphicalAbstractProvider } from 'contexts/useGraphicalAbstractContext';
import { CustomServiceProvider } from 'contexts/useCustomServiceContext';
// import { SocketProvider } from 'contexts/useSocketContext';
// import TermsModalContainer from 'containers/TermsModalContainer';
// import ForgotContainer from 'containers/auth/ForgotContainer';
import { TeamProvider } from 'contexts/useTeamContext';
import { TITLE } from 'config/app';
import Page from 'components/Page';
import { getEnv } from 'utils';
import { EmailFormModalProvider } from 'contexts/EmailFormModalContext';
import { TemplateProvider } from 'contexts/TemplateContext';
import { QueryClient } from '@tanstack/react-query';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client';
import { createAsyncStoragePersister } from '@tanstack/query-async-storage-persister';
// import Bugsnag from '@bugsnag/js';
// import BugsnagPluginReact from '@bugsnag/plugin-react';
import { ThemeProvider, createTheme, StyledEngineProvider } from '@mui/material/styles';
import { COLOR_PINK, COLOR_PURPLE } from 'styles/variables/colors';
import withActivityTimeout from 'utils/withActivityTimeout';
import { AuthUserProvider } from 'features/auth/AuthUserContext';
import { appWithTranslation } from 'next-i18next';
import { DatadogProvider } from 'contexts/DatadogContext';

const App = ({ ...props }) => {
  const router = useRouter();
  const [canonicalURL, setCanonicalURL] = useState('');
  const [clevertapModule, setClevertapModule] = useState(null);
  const containsAdminAndPresentation =
    router.asPath.includes('admin') || router.asPath.includes('presentation');

  // --> bugsnag temporarily disabled <--
  // Bugsnag.start({
  //  apiKey: process.env.BUGSNAG_FRONTEND_KEY,
  //  plugins: [new BugsnagPluginReact()],
  // });

  // const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);

  useEffect(() => {
    let path = router.pathname;

    if (router.query) {
      Object.keys(router.query).forEach((key) => {
        path = path.replace(`[${key}]`, router.query[key]);
      });
    }

    const cleanURL = `${getEnv('MTG_URL_APP')}${path}`;
    setCanonicalURL(cleanURL);
  }, [router.pathname, router.query]);

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        gcTime: 1000 * 60 * 60 * 12,
        staleTime: 2000,
        refetchOnWindowFocus: false,
        refetchOnMount: false,
      },
    },
  });

  const asyncStoragePersister = createAsyncStoragePersister({
    storage: AsyncStorage,
  });

  const theme = createTheme({
    typography: {
      fontFamily: 'Lato, sans-serif',
    },
    palette: {
      primary: {
        main: COLOR_PURPLE,
      },
      secondary: {
        main: COLOR_PINK,
      },
    },
  });

  function initGoogleAnalytics() {
    window.addEventListener('load', () => {
      // TagManager.initialize({ gtmId: getEnv('GTM_TRACKING_ID') });

      // ReactGA.initialize(getEnv('GA_TRACKING_ID'));

      // GDPR default consent
      ReactGA.set({ anonymizeIp: true });
      ReactGA.set({ gaOptions: { allowAdFeatures: false } });

      // Add Google Optimize to analytics
      // ReactGA.ga('require', getEnv('GO_TRACKING_ID'));

      if (getEnv('APP_ENV') === 'production') {
        ReactGA.send({
          hitType: 'pageview',
          page: window.location.pathname + window.location.search,
          trackers: ['default', 'alt'],
        });

        clevertap.event.push('Page Viewed', {
          Product: 'MTG',
          URL: window.location.pathname + window.location.search,
        });
      }

      router.events.on('routeChangeComplete', () => {
        ReactGA.send({
          hitType: 'pageview',
          page: window.location.pathname + window.location.search,

          trackers: ['default', 'alt'],
        });
      });
    });
  }

  function initFacebookPixel() {
    window.addEventListener('load', () => {
      const ids = getEnv('FB_PIXEL_ID')?.split(',');

      if (!ids) return;

      for (const id of ids) {
        import('react-facebook-pixel')
          .then((module) => module.default)
          .then((ReactPixel) => {
            ReactPixel.init(id, null, { autoConfig: true, debug: false });

            ReactPixel.pageView();

            router.events.on('routeChangeComplete', () => {
              ReactPixel.pageView();
            });
          });
      }
    });
  }

  function initGoogleConsent() {
    window.gtag = function () {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push(arguments);
    };

    window.gtag('consent', 'default', {
      ad_storage: 'denied',
      analytics_storage: 'denied',
      ad_user_data: 'denied',
      ad_personalization: 'denied',
    });
  }

  function initGdpr() {
    const element = document.createElement('script');
    element.src = '/assets/js/cactustech-gdpr.lib.js';
    element.async = true;
    document.body.appendChild(element);

    const styleElement = document.createElement('link');
    styleElement.rel = 'stylesheet';
    styleElement.href = '/assets/js/styles.css';
    document.head.appendChild(styleElement);
    window.addEventListener('load', function () {
      window._policies = {
        _config: {
          domain: window.location.hostname,
          revalidate: true,
        },
      };
    });

    document.addEventListener('consent_available', function (e) {
      // initTapfiliate(); // Due to the anonymous nature of the data we collect, we believe our cookies should be qualified as “Analytical cookies”. The current draft of the upcoming E-Privacy directive shows that exemptions to cookie consent requirements should be made for analytical cookies.

      if (e.detail.consent.functional === 'yes') {
        // --> Google Consent for Europe temporarily disabled <--
        // window.gtag('consent', 'update', {
        //   personalization_storage: 'granted',
        //   functionality_storage: 'granted',
        // });
      }
      if (e.detail.consent.targeting === 'yes') {
        ReactGA.set({ anonymizeIp: false });
        ReactGA.set({ gaOptions: { allowAdFeatures: true } });

        initFacebookPixel();

        // --> Google Consent for Europe temporarily disabled <--
        // window.gtag('consent', 'update', {
        //   ad_storage: 'granted',
        //   analytics_storage: 'granted',
        //   ad_user_data: 'granted',
        //   ad_personalization: 'granted',
        // });
      }
    });
  }

  function initTapfiliate() {
    const baseScriptElement = document.createElement('script');
    const detectionScriptElement = document.createElement('script');

    baseScriptElement.src = 'https://script.tapfiliate.com/tapfiliate.js';

    detectionScriptElement.text = `
      (function(t,a,p){t.TapfiliateObject=a;t[a]=t[a]||function(){(t[a].q=t[a].q||[]).push(arguments)}})(window,'tap');
      tap('create', '25653-0c3e2e', {integration: 'stripe'});
      tap('detect', {always_callback: true}, function (error, result) {});
    `;

    document.head.appendChild(baseScriptElement);
    document.head.appendChild(detectionScriptElement);
  }

  function setupGoogleRecaptcha() {
    window.recaptchaOptions = {
      useRecaptchaNet: true,
    };
  }

  async function initializeClevertap() {
    const clevertapModule = await import('clevertap-web-sdk');
    clevertapModule.default.init(process.env.CLEVERTAP_ACCOUNT_ID);
    clevertapModule.default.privacy.push({ optOut: false });
    clevertapModule.default.privacy.push({ useIP: false });
    clevertapModule.default.setLogLevel(3);

    return clevertapModule.default;
  }

  async function clevertapInit() {
    let clevertap = clevertapModule;

    if (!clevertap) {
      clevertap = await initializeClevertap();
      setClevertapModule(clevertap);
    }
  }

  function setupTrackingListeners() {
    window.addEventListener('load', () => {
      TagManager.initialize({ gtmId: getEnv('GTM_TRACKING_ID') });

      // GDPR default consent
      ReactGA.set({ anonymizeIp: true });
      ReactGA.set({ gaOptions: { allowAdFeatures: false } });

      // Add Google Optimize to analytics
      // ReactGA.ga('require', getEnv('GO_TRACKING_ID'));

      if (getEnv('APP_ENV') === 'production') {
        try {
          clevertap.event.push('Page Viewed', {
            Product: 'MTG',
            URL: window.location.pathname + window.location.search,
          });
        } catch (error) {
          console.error('Clevertap event error:', error);
        }
      }
    });
  }

  useEffect(() => {
    setupTrackingListeners();
    initGdpr();
  }, [router.events]);

  useEffect(() => {
    setupGoogleRecaptcha();
    clevertapInit();

    // --> Google Consent for Europe temporarily disabled <--
    // initGoogleConsent();
  }, []);

  return (
    <>
      <Head>
        <title>{TITLE}</title>

        <meta key="og:locale" name="og:locale" property="og:locale" content="en" />
        <meta key="og:type" name="og:type" property="og:type" content="website" />
        <meta
          key="og:site_name"
          name="og:site_name"
          property="og:site_name"
          content="Mind the Graph"
        />
        <meta
          key="og:title"
          name="og:title"
          property="og:title"
          content="Free Infographic Maker - Online Graphs and Infographics Creator for Doctors and Scientists - Mind the Graph"
        />
        <meta
          key="og:description"
          name="og:description"
          property="og:description"
          content="A free infographic maker & creator for medical doctors and scientists. Exclusive scientific illustrations, graphs and infographics for your papers, classes and graphical abstracts."
        />
        <meta
          key="og:url"
          name="og:url"
          property="og:url"
          content={`${getEnv('MTG_URL_APP')}${router.basePath}${router.asPath}`}
        />
        <meta
          key="og:image"
          name="og:image"
          property="og:image"
          content="/images/mtg-site-face.jpg"
        />
        <meta key="og:image:width" name="og:image:width" property="og:image:width" content="450" />
        <meta
          key="og:image:height"
          name="og:image:height"
          property="og:image:height"
          content="286"
        />
        <meta
          key="facebook-domain-verification"
          name="facebook-domain-verification"
          content="9j8ybgnsr62elhhbq1esghljijvvtg"
        />

        <link rel="canonical" href={canonicalURL} />
      </Head>

      {!containsAdminAndPresentation && (
        <Script src="https://widget.intercom.io/widget/gvophsej" strategy="afterInteractive" />
      )}

      <Script id="google-analytics" strategy="afterInteractive">
        {`(function(w,d,s,l,i){w[l] = w[l] || [];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-5P3SCHR');`}
      </Script>

      {/* <Script src="https://jscloud.net/lzs/20243/inlinks.js" strategy="afterInteractive" /> */}

      {/* <LinkedInInsightTag /> */}
      <GlobalStyle />
      <DatadogProvider
        config={{
          clientToken: process.env.DATADOG_CLIENT_TOKEN,
          applicationId: process.env.DATADOG_APPLICATION_ID,
          datadogEnv: process.env.DATADOG_ENV,
        }}
      >
        <PersistQueryClientProvider
          client={queryClient}
          persistOptions={{ persister: asyncStoragePersister }}
        >
          {/*<ErrorBoundary>*/}
          <AuthUserProvider>
            {/* <TermsModalProvider> */}
            <GalleryProvider>
              <BreadcrumbsProvider>
                {/* <ForgotProvider> */}
                <TeamProvider>
                  <CreationsProvider>
                    {/* <PosterProvider> */}
                    <GraphicalAbstractProvider>
                      <CustomServiceProvider>
                        <TemplateProvider>
                          <EmailFormModalProvider>
                            {/* <SocketProvider> */}
                            <StyledEngineProvider injectFirst>
                              <ThemeProvider theme={theme}>
                                <Page {...props} />
                                {/* <ForgotContainer /> */}
                                {/* <TermsModalContainer /> */}
                              </ThemeProvider>
                            </StyledEngineProvider>
                            {/* </SocketProvider> */}
                          </EmailFormModalProvider>
                        </TemplateProvider>
                      </CustomServiceProvider>
                    </GraphicalAbstractProvider>
                    {/* </PosterProvider> */}
                  </CreationsProvider>
                </TeamProvider>
                {/* </ForgotProvider> */}
              </BreadcrumbsProvider>
            </GalleryProvider>
            {/* </TermsModalProvider> */}
          </AuthUserProvider>
          {/*</ErrorBoundary>*/}
        </PersistQueryClientProvider>
      </DatadogProvider>
    </>
  );
};

export default appWithTranslation(withActivityTimeout(App));
