import { createContext, useState, useContext } from 'react';

export const initialState = {
  template: {
    id: null,
    topicsCount: 0,
    imageSrc: null,
    highlightImages: {
      title: null,
      highlight: null,
      topics: [],
    },
  },
  color: {
    id: null,
    primary: null,
    secondary: null,
  },
  content: {
    title: '',
    highlight: '',
    logo: { name: null, content: null },
  },
  topics: [],
  currentHighlight: {
    name: null,
  },
  suggestions: {
    templates: [
      {
        id: 1,
        topicsCount: 6,
        imageSrc: '/images/graphical-abstract/templates/template-1.png',
        highlightImages: {
          title: '/images/graphical-abstract/templates/template-1-title.png',
          highlight: '/images/graphical-abstract/templates/template-1-highlight.png',
          topics: [
            '/images/graphical-abstract/templates/template-1-topic-1.png',
            '/images/graphical-abstract/templates/template-1-topic-2.png',
            '/images/graphical-abstract/templates/template-1-topic-3.png',
            '/images/graphical-abstract/templates/template-1-topic-4.png',
            '/images/graphical-abstract/templates/template-1-topic-5.png',
            '/images/graphical-abstract/templates/template-1-topic-6.png',
          ],
        },
      },
      {
        id: 2,
        topicsCount: 4,
        imageSrc: '/images/graphical-abstract/templates/template-2.png',
        highlightImages: {
          title: '/images/graphical-abstract/templates/template-2-title.png',
          highlight: '/images/graphical-abstract/templates/template-2-highlight.png',
          topics: [
            '/images/graphical-abstract/templates/template-2-topic-1.png',
            '/images/graphical-abstract/templates/template-2-topic-2.png',
            '/images/graphical-abstract/templates/template-2-topic-3.png',
            '/images/graphical-abstract/templates/template-2-topic-4.png',
          ],
        },
      },
      {
        id: 3,
        topicsCount: 4,
        imageSrc: '/images/graphical-abstract/templates/template-3.png',
        highlightImages: {
          title: '/images/graphical-abstract/templates/template-3-title.png',
          highlight: '/images/graphical-abstract/templates/template-3-highlight.png',
          topics: [
            '/images/graphical-abstract/templates/template-3-topic-1.png',
            '/images/graphical-abstract/templates/template-3-topic-2.png',
            '/images/graphical-abstract/templates/template-3-topic-3.png',
            '/images/graphical-abstract/templates/template-3-topic-4.png',
          ],
        },
      },
      {
        id: 4,
        topicsCount: 3,
        imageSrc: '/images/graphical-abstract/templates/template-4.png',
        highlightImages: {
          title: '/images/graphical-abstract/templates/template-4-title.png',
          highlight: '/images/graphical-abstract/templates/template-4-highlight.png',
          topics: [
            '/images/graphical-abstract/templates/template-4-topic-1.png',
            '/images/graphical-abstract/templates/template-4-topic-2.png',
            '/images/graphical-abstract/templates/template-4-topic-3.png',
          ],
        },
      },
      {
        id: 5,
        topicsCount: 3,
        imageSrc: '/images/graphical-abstract/templates/template-5.png',
        highlightImages: {
          title: '/images/graphical-abstract/templates/template-5-title.png',
          highlight: '/images/graphical-abstract/templates/template-5-highlight.png',
          topics: [
            '/images/graphical-abstract/templates/template-5-topic-1.png',
            '/images/graphical-abstract/templates/template-5-topic-2.png',
            '/images/graphical-abstract/templates/template-5-topic-3.png',
          ],
        },
      },
    ],
    colors: [
      { id: 1, primary: '#8DADCB', secondary: '#8FD6F0' },
      { id: 2, primary: '#58227E', secondary: '#C1ABD2' },
      { id: 3, primary: '#EC4215', secondary: '#FBD2CE' },
      { id: 4, primary: '#3AA036', secondary: '#DDE8B5' },
      { id: 5, primary: '#008ACE', secondary: '#C5E9F4' },
      { id: 6, primary: '#81197C', secondary: '#9FCE54' },
      { id: 7, primary: '#040D4D', secondary: '#ED9C1C' },
    ],
    topicTitles: [],
    topicDescriptions: [],
    highlights: [],
  },
};

const GraphicalAbstractContext = createContext([initialState, () => {}]);

export function GraphicalAbstractProvider({ children }) {
  const [state, setState] = useState(initialState);
  const value = [state, (newState) => setState(newState)];

  return (
    <GraphicalAbstractContext.Provider value={value}>{children}</GraphicalAbstractContext.Provider>
  );
}

export function useGraphicalAbstractContext() {
  const [state, dispatch] = useContext(GraphicalAbstractContext);

  return [
    state,
    (newState) => {
      if (newState) {
        for (const propName in initialState) {
          if (!newState[propName]) {
            newState[propName] = state[propName];
          }
        }

        dispatch(newState);
      } else {
        dispatch({ ...initialState });
      }
    },
  ];
}
