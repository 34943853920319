import ApiService from './ApiService';

export default class TeamService {
  fetchAll() {
    return ApiService.get('/teams');
  }

  myTeam() {
    return ApiService.get('/teams/my-team');
  }

  myTeamAdmin(teamId) {
    return ApiService.get(`/teams/my-team-admin/${teamId}`);
  }

  findInviteByToken(token) {
    return ApiService.get(`/teams/invites/${token}`);
  }

  create(data) {
    return ApiService.post('/teams/', data);
  }

  update(id, data) {
    return ApiService.put(`/teams/${id}`, data);
  }

  createInvite(id, data) {
    return ApiService.post(`/teams/${id}/invites`, data);
  }

  removeInvite(id, inviteId) {
    return ApiService.delete(`/teams/${id}/invites/${inviteId}`);
  }

  removeMember(teamId, userId) {
    return ApiService.delete(`/teams/${teamId}/member/${userId}`);
  }

  acceptInvite(token) {
    return ApiService.put(`/teams/invites/${token}/accept`);
  }

  createTeamByAdmin(data) {
    return ApiService.post('/teams/create-team-by-admin', data);
  }

  getTeamLimit(id) {
    return ApiService.get(`/teams/get-team-limit/${id}`);
  }

  updateTeamLimit(id, data) {
    return ApiService.put(`/teams/update-team-limit/${id}`, data);
  }
}
